import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Elixir and Phoenix Developers, Philippines" />
    <div class="Container">
      <div class="Masthead">
        <div class="pv4 pv5-m pv6-l ph35 ph4-ns">
          <div class="Masthead_Inner">
            <div class="Masthead_Tagline">
              <div class="Masthead_TaglineText">
                Here’s to making your web more powerful
              </div>
              <Link to={`/contact`} class="Button">Let's do it</Link>
            </div>
            <div class="Masthead_Blurb">
              <div class="measure white-80">
                <p class="mt0">
                  We are an independent web development agency committed to building high-performing web apps that are fast, scalable, and sustainable.
                </p>
                <p class="mb0">
                  Our strength comes from the desire to maximize the capabilities of web as a platform in creating greater value for growing brands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="Capabilities">
      <div class="Capabilities_Bg">
        <div class="Capabilities_BgDarkFade"></div>
        <div class="Capabilities_BgDark"></div>
        <div class="Capabilities_BgAccentFade"></div>
        <div class="Capabilities_BgAccent"></div>
      </div>
      <div class="Container">
        <div class="Capabilities_Inner">
          <div class="Capabilities_Tech">
            <div class="Capabilities_TechBgDarkFade"></div>
            <div class="Capabilities_TechBgDark"></div>
            <div class="Capabilities_TechBgAccentFade"></div>
            <div class="Capabilities_TechBgAccent"></div>

            <div class="w-100 pl35 pl0-ns pr35 pr4-ns">
              <h2 class="SectionHeader">Our tech lets us move at full speed</h2>
              <p class="ma0 mb4 ttu tracked lh-copy" style={{ color: '#627CB9' }}>
                Progressive Web Development
              </p>
              <div class="Tech">
                <div class="Tech_Items">
                  <div class="Tech_Item Tech_Item-Elixir">
                    <div class="Tech_Icon"></div>
                    <div class="Tech_Details">
                      <div class="Tech_Language">Elixir</div>
                      <div class="Tech_Specialty">Phoenix</div>
                    </div>
                  </div>
                  <div class="Tech_Item Tech_Item-Ruby">
                    <div class="Tech_Icon"></div>
                    <div class="Tech_Details">
                      <div class="Tech_Language">Ruby</div>
                      <div class="Tech_Specialty">Rails</div>
                    </div>
                  </div>
                  <div class="Tech_Item Tech_Item-Javascript">
                    <div class="Tech_Icon"></div>
                    <div class="Tech_Details">
                      <div class="Tech_Language">JavaScript</div>
                      <div class="Tech_Specialty">React</div>
                    </div>
                  </div>
                </div>
                <div class="Tech_Etc">
                  <div>· · ·</div>
                  <div>Vue</div>
                  <div>PostgreSQL</div>
                  <div>Redis</div>
                  <div>GraphQL</div>
                  <div>AWS</div>
                  <div>Heroku</div>
                  <div>Kubernetes</div>
                  <div>Docker</div>
                  <div>Truffle</div>
                  <div>CircleCI</div>
                  <div>Coveralls</div>
                  <div>etc.</div>
                </div>
              </div>
              <div class="mt4 measure lh-copy white-80">
                <p class="ma0">New innovations may come and go, but technology that’s stable, reliable, and widely adopted is still king. We build on platforms that can support growth, making sure we're never left behind.</p>
              </div>
            </div>
          </div>

          <div class="Capabilities_Process">
            <div class="Capabilities_ProcessBg">
              <div class="Capabilities_ProcessBgDarkFade2"></div>
              <div class="Capabilities_ProcessBgDark"></div>
              <div class="Capabilities_ProcessBgAccentFade2"></div>
              <div class="Capabilities_ProcessBgAccent"></div>
            </div>

            <div class="Capabilities_ProcessInner">
              <div class="ph35 ph4-ns" style={{ margin: 'auto 0' }}>
                <h2 class="SectionHeader">Agile is the way we run</h2>
                <div class="measure mb4 white-80">
                  <p class="lh-copy">The way we work supercharges the apps we build. A modern software development mindset allows us to deliver work that is</p>
                </div>
                <div class="Process">
                  <div class="Process_Item">
                    <div class="Process_Name">Dynamic</div>
                    <div class="Process_Desc measure">Our continuous integration and delivery process allow for rapid iterations.</div>
                  </div>
                  <div class="Process_Item">
                    <div class="Process_Name">Sustainable</div>
                    <div class="Process_Desc measure">We future-proof our solutions by running automated tests and code quality checks which we monitor constantly.</div>
                  </div>
                  <div class="Process_Item">
                    <div class="Process_Name"> Exceptional</div>
                    <div class="Process_Desc measure">We employ pair programming and code reviews for minimal oversight.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Goals">
      <div class="Container">
        <div class="pv5 pb6-ns ph35 ph4-ns">
          <h2 class="SectionHeader tc">
            Imagine your greatness, multiplied by ours
          </h2>
          <div class="flex-ns justify-center-ns">
            <div class="measure">
              <div class="Goals_Item Goals_Item-Augment">
                <div class="Goals_Name">
                  TECH AUGMENTATION
                </div>
                <p class="Goals_Desc">
                  Whether you’re an agency who needs extra engineering power, or a designer who needs a technical partner, we can create your app together.
                </p>
              </div>
            </div>
            <div class="measure">
              <div class="Goals_Item Goals_Item-Business">
                <div class="Goals_Name">
                  BUSINESS / MVP
                </div>
                <p class="Goals_Desc">
                  It doesn’t matter how much you know about the web. We are here to understand your business needs and create the best online solution to help achieve your goals.
                </p>
              </div>
            </div>
          </div>
          <div class="mt4">
            <p class="Goals_Action ph3 ph0-ns">
              <Link to={`/contact`}>Ask us</Link>
              <span>about our experience in esports, fintech, events, blockchain, and ecommerce.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="Join">
      <div class="Container">
        <div class="pv5 pv6-ns ph35 ph4-ns">
          <div class="flex-ns">
            <div class="mb4 mb0-ns w-50-ns flex-ns pr4-ns">
              <h2 class="SectionHeader" style={{ margin: 'auto 0' }}>
                Great power comes from great culture
              </h2>
            </div>
            <div class="w-50-ns">
              <div class="measure lh-copy white-80">
                <p class="mt0">
                  Polydara follows an engineering-centric approach to management and leadership. We create and support learning opportunities to improve your technological expertise, whichever function you may be assigned to.
                </p>
                <p>
                  We are actively seeking team members that believe in what we do.
                </p>
              </div>
              <div class="mt4">
                <a class="Button" href="mailto:careers@polydara.com">Join our team</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
